/* eslint-disable */
import HttpService from '../HttpService';
export default class InsuredService {
  constructor() {
    this._httpService = new HttpService('/contract/insured');
  }

  async FindById(queryString) {
    return await this._httpService.get(`/${queryString}`);
  }
  
  async FindAllByFilters(params) {
    return await this._httpService.get(`?${params}`);
  }

  async updateBeneficiaryDate(data) {
    return await this._httpService.put(`/update-beneficiary`,data);
  }
};
